import React from "react";

function Card(props) {
    return (
        <>
            <div className="flex flex-col gap-2 px-7 py-10 h-full border-2 border-black rounded-[1rem] hover:scale-[1.03] hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.2)] transition-all ease-in-out duration-300">
                <div className="mb-16">
                    <ion-icon
                        name={props.iconName}
                        size="large"
                    ></ion-icon>
                </div>
                <h2 className="text-[1.4rem] font-medium">
                    {props.title}
                </h2>
                <p className="text-[1rem] font-thin">
                    {props.description}
                </p>
            </div>
        </>
    );
}

export default Card;
