import React, { useRef } from "react";

import Header from "../Components/Header";
import Card from "../Components/Card";
import Accordion from "../Components/Accordion";
import Form from "../Components/Form";

import blondeGirl from "../img/blondeGirl.png";

function Home() {
    const homeRef = useRef(null);
    const servicesRef = useRef(null);
    const faqRef = useRef(null);
    const applyRef = useRef(null);

    const scrollTo = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: "smooth",
        });
    };

    return (
        <>
            <Header
                homeClick={() => scrollTo(homeRef)}
                servicesClick={() => scrollTo(servicesRef)}
                faqClick={() => scrollTo(faqRef)}
                applyClick={() => scrollTo(applyRef)}
            />

            <main className="bg-white">
                <section
                    className="bg-[#FF5FE5] h-[56rem] md:h-screen relative overflow-hidden flex flex-col justify-start md:justify-center items-start gap-10 sm:gap-16 md:gap-20 px-5 sm:px-14 xl:px-40"
                    ref={homeRef}
                >
                    <div className="flex flex-col gap-2 md:gap-8 pt-10">
                        <div className="text-white flex flex-col leading-[5.6rem] md:leading-[7.4rem] md:pt-[6rem]">
                            <h1 className="text-[4.2rem] sm:text-[6rem] font-bold text-transparent font-outline-2">
                                Alcance o
                            </h1>
                            <h1 className="text-[5.2rem] sm:text-[7rem] font-bold ">
                                Próximo
                            </h1>
                            <h1 className="text-[4.2rem] sm:text-[6rem] font-bold">
                                Nível
                            </h1>
                        </div>
                        <div>
                            <p className="text-white text-[1rem] font-extralight">
                                A Aura te ajuda a monetizar
                                e escalar seus conteúdos
                                exclusivos. Construa e
                                <br />
                                desenvolva a sua jornada no
                                OnlyFans conosco.
                            </p>
                        </div>
                    </div>

                    <button
                        className="font-bold text-[1.2rem] text-white bg-sky-400 px-[1.8rem] py-[.8rem] border-2 border-sky-400 rounded-[1rem] hover:bg-transparent hover:border-white transition-all ease-in-out duration-300"
                        onClick={() => scrollTo(applyRef)}
                    >
                        Começe Agora
                    </button>
                    <img
                        src={blondeGirl}
                        className="transform h-[22rem] md:h-[28rem] lg:h-[35rem] xl:h-[42rem] absolute bottom-0 left-1/2 translate-x-[-50%] md:left-[76%] opacity-80"
                        alt="Blonde Girl"
                        draggable="false"
                    />
                </section>

                <section
                    className=" sm:h-[108rem] lg:h-[65rem] xl:h-screen bg-white flex flex-col justify-center items-center gap-24 py-10 sm:py-0"
                    ref={servicesRef}
                >
                    <h1 className="text-[#FF5FE5] text-[4rem] font-semibold">
                        Serviços
                    </h1>
                    <div className="grid justify-center items-start grid-rows-4 lg:grid-rows-2 xl:grid-rows-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-6 px-10 md:px-32 w-full">
                        <Card
                            iconName="phone-portrait-outline"
                            title="Gerenciamento do OnlyFans"
                            description="Nós fornecemos um gerenciamento completo de OnlyFans: serviços de chat 24/7, Posts & Marketing para turbinar seus ganhos. Foco em seus conteúdos nos mais altos padrões."
                        />
                        <Card
                            iconName="share-social-outline"
                            title="Redes Sociais"
                            description="Nós te ajudamos a crescer suas redes sociais com conteúdos direcionados para atingir seu potencial máximo."
                        />
                        <Card
                            iconName="podium-outline"
                            title="Marketing Customizado"
                            description="Nós identificamos seu nicho e vamos promover seu Onlyfans em diferentes plataformas como Reddit, Tiktok, Instagram, Twitter. Aumentando sua base de seguidores, aumentamos seu faturamento."
                        />
                        <Card
                            iconName="videocam-outline"
                            title="Criação de Conteúdo"
                            description="Somando com sua criatividade, nós mandaremos sugestões semanais de conteúdos para aumentar seu faturamento no OnlyFans e crescer seu alcance nas redes sociais."
                        />
                    </div>
                </section>

                <section
                    className="bg-[#FF5FE5] flex flex-col justify-between items-center py-32"
                    ref={faqRef}
                >
                    <h1 className="text-white text-[4rem] font-semibold mb-16">
                        FAQ
                    </h1>
                    <div className="w-2/3">
                        <Accordion
                            number="001"
                            question="Com qual frequência eu estarei em contato com meu Gerente de Contas?"
                            answer="
                            Nós somos acessíveis 24/7 para você. Isso é totalmente de você se quer ter uma conversa diária ou até mesmo um check-in semanal/mensal. Nós temos tanto os recursos humanos para prover uma troca diária assim como a infraestrutura técnica para trabalhar eficientemente mesmo tendo a mínima troca pessoal."
                        />
                        <Accordion
                            number="002"
                            question="Como podemos começar a trabalhar juntos?"
                            answer="
                            Você pode aplicar diretamente pelo Formulário do site. Nós revisamos suas informações e entraremos em contato com você em até 24 horas."
                        />
                        <Accordion
                            number="003"
                            question="Eu não tenho experiência com OnlyFans, vou ser considerada?"
                            answer="
                            Com certeza! Embora nosso foco seja em crescer absurdamente contas que já existem, nós também consideramos iniciantes."
                        />
                        <Accordion
                            number="004"
                            question="Qual tipo de conteúdo eu terei que criar?"
                            answer="
                            Juntamente com o seu Gerente de Contas, você definirá o tipo de conteúdo ideal desejado e altamente relacionado ao sucesso. Você só terá que produzir os conteúdos em que se sentir absolutamente confortável."
                        />
                        <Accordion
                            last={true}
                            number="005"
                            question="Quanto esse serviço irá custar?"
                            answer="Depois que passar da fase de aplicação, nós estabelecemos um contrato ás nossas custas. Investiremos tempo e dinheiro com o objetivo de atingir os melhores resultados possíveis juntos. Nós só faremos dinheiro, quando a sua conta fazer dinheiro. Não existe nenhum custo escondido. Nós só teremos uma porcentagem dos ganhos da sua conta. Quanto maior seus ganhos, menor a nossa porcentagem!"
                        />
                        {/* <Accordion
                            number="001"
                            question="Can i remain private and still be sucessful on OnlyFans?"
                        /> */}
                    </div>
                </section>

                <section
                    // bg-[url('/src/img/backgroundGirl.jpeg')] bg-no-repeat bg-cover bg-center
                    className="py-32 flex flex-col justify-center items-center"
                    ref={applyRef}
                >
                    <h1 className="text-[#FF5FE5] text-[4rem] font-semibold mb-16 text-center">
                        Queremos te conhecer!
                    </h1>
                    <Form />
                </section>
            </main>
        </>
    );
}

export default Home;
