import React, { useState } from "react";
import Navbar from "./Navbar";

import logo_name from "../img/logoName.png";

function Header(props) {
    const [menuOpen, setMenuOpen] = useState(false);

    const redirectHandle = () => {
        window.open(
            "https://www.instagram.com/aura.managing",
            "_blank"
        );
    };

    return (
        <>
            <header className="lg:fixed z-10 top-0 w-screen bg-white px-10 flex justify-between lg:justify-around items-center">
                <div className="cursor-pointer">
                    <img
                        src={logo_name}
                        className="w-20"
                        alt="Logo da Agência"
                        onClick={props.homeClick}
                        draggable="false"
                    ></img>
                </div>

                <Navbar
                    homeClick={props.homeClick}
                    servicesClick={props.servicesClick}
                    faqClick={props.faqClick}
                    isMenuOpen={menuOpen}
                    setIsMenuOpen={setMenuOpen}
                />
                <div className="hidden lg:flex items-center gap-10">
                    <button
                        className="flex font-bold text-[1.2rem] text-white bg-sky-400 px-[1.6rem] py-[.6rem] rounded-[1rem] border-sky-400 border-2 hover:bg-white hover:text-sky-400 transition-all ease-in-out duration-300"
                        onClick={props.applyClick}
                    >
                        Aplicar
                    </button>
                    <button
                        className="flex items-center"
                        onClick={() => redirectHandle()}
                    >
                        <ion-icon
                            name="logo-instagram"
                            size="large"
                        />
                    </button>
                </div>

                <div
                    className="flex justify-center lg:hidden"
                    onClick={() => {
                        setMenuOpen(!menuOpen);
                    }}
                >
                    <ion-icon
                        name={`${
                            menuOpen
                                ? "close-outline"
                                : "menu-outline"
                        }`}
                        size="large"
                    />
                </div>
            </header>

            <div
                className={`lg:hidden grid bg-white overflow-hidden px-10 transition-all ease-in-out duration-[350ms] ${
                    menuOpen
                        ? "grid-rows-[1fr] opacity-100 mb-5"
                        : "grid-rows-[0fr] opacity-0 mb-0"
                }`}
            >
                <ul className="overflow-hidden flex flex-col gap-6 font-light text-[1.05rem]">
                    <li onClick={props.homeClick}>
                        Início
                    </li>
                    <li onClick={props.servicesClick}>
                        Serviços
                    </li>
                    <li onClick={props.faqClick}>FAQ</li>
                </ul>
            </div>
        </>
    );
}

export default Header;
