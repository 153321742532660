import React from "react";

function Input(props) {
    const { label, onChange, id, ...inputProps } = props;

    return (
        <>
            <div className="flex flex-col gap-2">
                <label className="font-light text-[1rem] focus:text-[#FF5FE5]">
                    {label}
                </label>
                <input
                    className="bg-transparent border-b-2 border-black outline-none text-[1rem] font-light p-1 focus:border-[#FF5FE5]"
                    {...inputProps}
                    onChange={onChange}
                />
            </div>
        </>
    );
}

export default Input;
