import React, { useState } from "react";

import Input from "../Components/Input";

function Form() {
    const [values, setValues] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Instagram: "",
        Onlyfans: "",
        privacy: "",
        outra: "",
        nenhuma: "",
    });
    const [isSending, setIsSending] = useState(false);

    const inputs = [
        {
            id: 1,
            name: "Name",
            type: "text",
            placeholder: "",
            label: "Seu nome*",
        },
        {
            id: 2,
            name: "Email",
            type: "text",
            placeholder: "",
            label: "Seu e-mail*",
        },
        {
            id: 3,
            name: "Phone",
            type: "text",
            placeholder: "+55 11 981234437",
            label: "Telefone*",
        },
        {
            id: 4,
            name: "Instagram",
            type: "text",
            placeholder: "@nomedeusuario",
            label: "Seu @ do Instagram*",
        },
        // {
        //     id: 5,
        //     name: "onlyfans",
        //     type: "checkbox",
        //     label: "OnlyFans",
        // },
        // {
        //     id: 6,
        //     name: "privacy",
        //     type: "checkbox",
        //     label: "Privacy",
        // },
        // {
        //     id: 7,
        //     name: "outro",
        //     type: "checkbox",
        //     label: "Outra",
        // },
        // {
        //     id: 8,
        //     name: "nenhuma",
        //     type: "checkbox",
        //     label: "Nenhuma",
        // },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            values.Name &&
            values.Email &&
            values.Phone &&
            values.Instagram
        ) {
            setIsSending(true);
            const formEl = document.querySelector("form");

            const formData = new FormData(formEl);
            fetch(
                "https://script.google.com/macros/s/AKfycbw2xeR4M2s1qtOtpl2LSEJ7Y1iFGoW0Svy2lD-WzHUk4tw-M8GLq3xi655EuX2YOoxBCA/exec",
                {
                    method: "POST",
                    body: formData,
                }
            ).then((response) => {
                if (response.ok) {
                    setValues({
                        Name: "",
                        Email: "",
                        Phone: "",
                        Instagram: "",
                    });
                    setIsSending(false);
                }
            });
        }
    };

    const onChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <form
                className="flex flex-col items-center gap-10"
                onSubmit={handleSubmit}
            >
                {inputs.map((input) => {
                    return (
                        <Input
                            key={input.id}
                            {...input}
                            value={values[input.name]}
                            onChange={onChange}
                        />
                    );
                })}
                <button
                    className={`relative ${
                        isSending
                            ? "bg-white cursor-default"
                            : "bg-[#FF5FE5]"
                    } h-14 w-40 text-white font-bold text-[1.2rem] rounded-[1rem] border-2 border-[#FF5FE5] hover:bg-white hover:text-[#FF5FE5] transition-all ease-in-out duration-300`}
                    disabled={isSending}
                >
                    {isSending && (
                        <div className="border-4 border-[255, 95, 229, 0.5] rounded-[50%] border-t-[#FF5FE5] opacity-100 absolute left-1/4 right-1/4 top-1/4 bottom-1/4 m-auto w-6 h-6 transition-opacity duration-[250] animate-spin" />
                    )}
                    {isSending ? "" : "Enviar"}
                </button>
            </form>
        </>
    );
}

export default Form;
