import React, { useState } from "react";

function Accordion(props) {
    const [accordionOpen, setAccordionOpen] =
        useState(false);

    return (
        <>
            <div
                className={`border-t-2 ${
                    props.last && "border-b-2"
                }`}
            >
                <button
                    className="flex justify-start items-center gap-[4.6rem] py-10 w-full hover:pl-4 transition-all ease-in-out duration-300"
                    onClick={() =>
                        setAccordionOpen(!accordionOpen)
                    }
                >
                    <spam className="text-[1.2rem] text-white font-normal">
                        {props.number}
                    </spam>
                    <h2 className="text-[1.2rem] text-white font-medium">
                        {props.question}
                    </h2>
                </button>
                <div
                    className={`grid text-[1rem] overflow-hidden px-10 text-white font-normal transition-all ease-in-out duration-300 ${
                        accordionOpen
                            ? "grid-rows-[1fr] pt-5 pb-10 opacity-100 "
                            : "grid-rows-[0fr] opacity-0"
                    }`}
                >
                    <div className="overflow-hidden">
                        {props.answer}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Accordion;
