import React from "react";

function Navbar(props) {
    return (
        <>
            <ul className="hidden lg:flex gap-14 font-normal text-[1.05rem]">
                <li
                    className="cursor-pointer hover:text-gray-400 transition-all ease-in-out duration-300"
                    onClick={props.homeClick}
                >
                    Início
                </li>
                <li
                    className="cursor-pointer hover:text-gray-400 transition-all ease-in-out duration-300"
                    onClick={props.servicesClick}
                >
                    Serviços
                </li>
                <li
                    className="cursor-pointer hover:text-gray-400 transition-all ease-in-out duration-300"
                    onClick={props.faqClick}
                >
                    FAQ
                </li>
            </ul>
        </>
    );
}

export default Navbar;
